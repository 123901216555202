interface ApiConfig {
  API_URL: string;
  GAME_API_URL: string;
  ADMIN_API_URL: string;
}

interface Config {
  api: ApiConfig;
}

const config: Config = {
  api: {
    API_URL: 'https://lov88.com/admin',
    GAME_API_URL: 'https://lov88.com/admin',
    ADMIN_API_URL: 'https://lov88.com/admin',
    // GAME_API_URL: 'https://api-game.ppbet.io',
    // ADMIN_API_URL: 'https://api-admin.ppbet.io',

    // API_URL: 'https://adminapi.hw-123.com',
    // GAME_API_URL: 'https://api.hw-123.com',
    // ADMIN_API_URL: 'https://adminapi.hw-123.com',
  },
};

export default config;
