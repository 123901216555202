import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GetUsers as getUsersApi,
  GetJoins as getJoinsApi, ApproveJoin as ApproveJoinApi, DenyJoin as DenyJoinApi,
  GetChargeRequests as ChargeRequestsApi, ApproveCharge as ApproveChargeApi, DenyCharge as DenyChargeApi,
  GetTransferRequests as TransferRequestsApi, ApproveTransfer as ApproveTransferApi, DenyTransfer as DenyTransfereApi,

  addNewUser as addNewUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
} from "../../helpers/backend_helper";

export const getUsers = createAsyncThunk("users/get" , async () => {

  try{
    let response = getUsersApi({      
    });
	let data = await response;
	data = data.data;
    return data;
  }catch (error) {
    return error;
  }
})

export const addNewUser = createAsyncThunk("crm/addNewLead" , async (lead:any) => {
  try{
    const response = addNewUserApi(lead)
    toast.success("Lead Added Successfully", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Lead Added Failed", { autoClose: 3000 });
    return error;
  }
})

export const updateUser = createAsyncThunk("crm/updateLead" , async (lead:any) => {
  try{
    const response = updateUserApi(lead)
    toast.success("Lead Updated Successfully", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Lead Updated Failed", { autoClose: 3000 });
    return error;
  }
})

export const deleteUser = createAsyncThunk("crm/deleteLead" , async (leads:any) => {
  try{
    const response = deleteUserApi(leads)
    toast.success("Lead Deleted Successfully", { autoClose: 3000 });
    return { leads, ...response };

  }catch (error) {
    toast.error("Lead Deleted Failed", { autoClose: 3000 });
    return error;
  }
})

export const getJoins = createAsyncThunk("users/joins" , async () => {
	try{
		let response = getJoinsApi({      
		});
	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
	  	return error;
	}
})

export const approveJoin = createAsyncThunk("/join/approve" , async ( data: any ) => {
	try{
		let response = ApproveJoinApi({
      		data: data,
		});
		toast.success("Join Approve Updated Successfully", { autoClose: 5000 });

	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
		toast.error("Join Approve Updated Failed", { autoClose: 5000 });
	  	return error;
	}
})

export const denyJoin = createAsyncThunk("/join/deny" , async (data: any) => {
	try{
		let response = DenyJoinApi({      
			data: data,
		});
		toast.success("Join Deny Updated Successfully", { autoClose: 5000 });

	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
		toast.error("Join Deny Updated Failed", { autoClose: 5000 });
	  	return error;
	}
})

export const getChargeRequests = createAsyncThunk("charge/charge/requests" , async () => {
	try{
		let response = ChargeRequestsApi({      
		});
	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
	  	return error;
	}
})

export const approveCharge = createAsyncThunk("/charge/approve" , async ( data: any ) => {
	try{
		let response = ApproveChargeApi({
      		data: data,
		});
		toast.success("Charge Approve Updated Successfully", { autoClose: 5000 });

	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
		toast.error("Charge Approve Updated Failed", { autoClose: 5000 });
	  	return error;
	}
})

export const denyCharge = createAsyncThunk("/charge/deny" , async (data: any) => {
	try{
		let response = DenyChargeApi({      
			data: data,
		});
		toast.success("Charge Deny Updated Successfully", { autoClose: 5000 });

	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
		toast.error("Charge Deny Updated Failed", { autoClose: 5000 });
	  	return error;
	}
})

export const getTransferRequests = createAsyncThunk("/transfer/requests" , async () => {
	try{
		let response = TransferRequestsApi({
		});
	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
	  	return error;
	}
})

export const approveTransfer = createAsyncThunk("/transfer/approve" , async ( data: any ) => {
	try{
		let response = ApproveTransferApi({
      		data: data,
		});
		toast.success("Transfer Approve Updated Successfully", { autoClose: 5000 });

	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
		toast.error("Transfer Approve Updated Failed", { autoClose: 5000 });
	  	return error;
	}
})

export const denyTransfer = createAsyncThunk("/transfer/deny" , async (data: any) => {
	try{
		let response = DenyTransfereApi({      
			data: data,
		});
		toast.success("Transfer Deny Updated Successfully", { autoClose: 5000 });

	  	let result = await response;
	  	result = result.data;
	  	return result;
	} catch (error) {
		toast.error("Transfer Deny Updated Failed", { autoClose: 5000 });
	  	return error;
	}
})