import axios, {AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults} from 'axios';
import config from '../config';
import {UseAdminAuthStore} from "../store/auths";

const { api } = config;

axios.defaults.baseURL = api.ADMIN_API_URL;
axios.defaults.headers['Content-Type'] = 'application/json';

const authUser: any = localStorage.getItem('admin_auth');
const accessToken = JSON.parse(authUser)
  ? JSON.parse(authUser).state.accessToken
  : null;
if (accessToken)
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

const baseConfig: CreateAxiosDefaults = {
  baseURL: api.ADMIN_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

let isTokenRefreshing = false;
let failedRequests: any[] = [];

function onRefreshed(token: any) {
  failedRequests.map((cb) => cb(token));
}


function addSubscriber(callback: any) {
  failedRequests.push(callback);
}

export const instanceWithoutInterceptors = axios.create(baseConfig);

export const instance = axios.create(baseConfig);

instance.interceptors.request.use(
    (config) => {
      const accessToken = UseAdminAuthStore.getState().accessToken;

      if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken;
      }

      return config;
    },
);

instance.interceptors.response.use(
    function (response: any) {

      return response.data ? response.data : response;
    },
    async function (error:any) {
      const { response,config } = error;
      const status = response?.status;

      if (status !== 401) {
        return Promise.reject(error);
      }

      if (status === 401 && !!config._retry === false){
        const originalRequestConfig = config;
        config._retry = true;

        if (!isTokenRefreshing){
          isTokenRefreshing = true;

          const url = api.ADMIN_API_URL + '/auth/partner-token-refresh';
          const refreshToken = UseAdminAuthStore.getState().refreshToken;

          await axios.post(url,{},{
            headers:{
              'Authorization': 'Bearer ' + refreshToken,
              'Content-Type': 'application/json',
            }
          }).then((response:any)=>{
            if (response.data.success === true) {
              const token = response.data.token;

              UseAdminAuthStore.setState((state:any)=>({
                ...state,
                accessToken:token.token,
                refreshToken:token.refresh_token,
              }));

              isTokenRefreshing = false;
              onRefreshed(token.token)
              failedRequests = [];
            } else {
              alert('로그인 유효시간이 만료되었습니다. 다시 로그인하세요.');
              window.location.replace('/login');
            }
          }).catch((err:any)=>{
            console.error('Refresh token request failed:', err);
            alert('로그인 유효시간이 만료되었습니다. 다시 로그인하세요.');
            window.location.replace('/login');
          });
        }

        const retryOriginalRequest = new Promise((resolve, reject) => {
          addSubscriber((access: string) => {
            originalRequestConfig.headers['Authorization'] = `Bearer ${access}`;
            resolve(instance(originalRequestConfig));
          });
        });

        return retryOriginalRequest;
      } else if (status === 500){
        return Promise.reject('too many request');
      }

      return response
    },
);

export const LoginRequest = async function (options: any) {
  return await instanceWithoutInterceptors(options).then((response) => (response.data ? response.data : response));;
};

export const request = async function (options: any) {
  return await instance(options);
};

class APIClient {
  /**
   * Fetches data from the given URL
   */
  static get = (url: string, params?: any): Promise<AxiosResponse> => {
    let response: Promise<AxiosResponse>;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join('&') : '';
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  /**
   * Posts the given data to the URL
   */

  static create = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.post(url, data);
  };

  static PostBody = (url: string, data?: any) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  static update = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.patch(url, data);
  };

  static put = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  static delete = (
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = sessionStorage.getItem('authUser');
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient,  getLoggedinUser };