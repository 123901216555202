import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
const AdminNonAuthLayout = ({ children }: any) => {
    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutThemeType: layout.layoutThemeType,
            layoutModeType: layout.layoutModeType,
        })
    );

    const {
        layoutModeType,
        layoutThemeType,
    } = useSelector(selectLayoutProperties);
    useEffect(() => {
        document.body.setAttribute("data-theme", layoutThemeType);
        document.body.setAttribute("data-bs-theme", "dark");

        return () => {
            document.body.removeAttribute("data-bs-theme");
            document.body.removeAttribute("data-theme");
        };
    }, [layoutModeType,layoutThemeType]);
    return (
        <div>
            {children}
        </div>
    )
}
export default AdminNonAuthLayout