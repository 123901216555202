import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row, Spinner,} from 'reactstrap';
import withRouter from 'Components/Common/withRouter';

import config from 'config';
import {request} from "../../../helpers/api_helper";

const {api} = config;

const DemoPage = (props: any) => {
    document.title = 'Demo Game Play';

    const [Loader, SetLoader] = useState<boolean>(false);
    const [Error, SetError] = useState<string>('');


    useEffect(() => {
        SetError('');
        SetLoader(false);
    }, []);

    const GameStartLink = async () => {
        try {
            let request_url: string = api.ADMIN_API_URL + '/demo/game-link';
            await request({
                method: 'POST',
                url: request_url
            })
                .then((result: any) => {
                    SetLoader(false);
                    if (result.success) {
                        const link = result.link;
                        window.open(link, '_blank', 'noopener,noreferrer');
                    } else {
                        SetError(result.error);
                    }
                })
                .catch((error) => {
                    SetLoader(false);
                    SetError('아이디 또는 비밀번호를 확인하세요');
                });
        } catch (error) {
            SetLoader(false);
        }
    };

    return (
        <React.Fragment>
            <div className="auth-page-content min-vh-100">
                <Container className={"vh-100"}>
                    <Row className="justify-content-center h-100 align-items-center">
                        <Col md={4} lg={4} xl={4}>
                            <Card color={"dark"} className="mt-4 card-bg-fill">
                                <CardBody className="p-4">
                                    <div className={"d-flex justify-content-end mb-5"}>
                                        <Button color={'link'} href="/login">로그아웃</Button>
                                    </div>
                                    <div className="text-center mt-2">
                                        <h5 className="text-white-50">Demo Game Play</h5>
                                    </div>
                                    <div className="p-2 mt-2">
                                        <div className="mt-4">
                                            <Button
                                                color="info"
                                                disabled={Loader && true}
                                                className="btn btn-success w-100"
                                                onClick={() => GameStartLink()}
                                            >
                                                {Loader && (
                                                    <Spinner size="sm" className="me-2">
                                                        {' '}
                                                        로딩중...{' '}
                                                    </Spinner>
                                                )}
                                                게임 시작
                                            </Button>
                                            <br/>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(DemoPage);
