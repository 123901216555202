import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AdminAuthState {
    isAdminAuthenticated: boolean;
    admin_id: number;
    admin_type: number;
    admin_name: string;
    site_balance: number;
    accessToken: string;
    refreshToken: string;

    Login: (
        id: number,
        name: string,
        type: number,
        balance: number,
        accessToken: string,
        refreshToken: string,
    ) => void;
    Logout: () => void;
    RefreshToken: (accessToken: string, refreshToken: string) => void;
}

interface AccessTokenState {
    accessToken: string;
}
export const AccessTokenStore = create<AccessTokenState>()(
    persist(
        (set) => ({
            accessToken: '',

            setAccessToken: (accessToken: string) => {},
        }),
        {
            name: 'accessToken',
        },
    ),
);

export const UseAdminAuthStore = create<AdminAuthState>()(
    persist(
        (set) => ({
            isAdminAuthenticated: false,
            admin_id: -1,
            admin_type: -1,
            admin_name: '',
            site_balance: 0,
            accessToken: '',
            refreshToken: '',

            Login: (
                id: number,
                name: string,
                type: number,
                balance: number,
                accessToken: string,
                refreshToken: string,
            ) =>
                set((state: any) => ({
                    ...state,
                    isAdminAuthenticated: true,
                    admin_id: id,
                    admin_name: name,
                    admin_type: type,
                    site_balance: balance,
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                })),

            Logout: () =>
                set((state: any) => ({
                    ...state,
                    isAdminAuthenticated: false,
                    admin_id: -1,
                    admin_type: -1,
                    admin_name: '',
                    site_balance: 0,
                    accessToken: '',
                    refreshToken: '',
                })),

            RefreshToken: (accessToken: string, refreshToken: string) =>
                set((state: any) => ({
                    ...state,
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                })),
        }),
        {
            name: 'admin_auth',
        },
    ),
);

interface UserAuthState {
    isUserAuthenticated: boolean;
    user_name: string;
    nick_name: string;
    user_balance: number;

    UserLogin: (name: string, nickname: string, balance: number) => void;
    UserLogout: () => void;
}

export const UseUserAuthStore = create<UserAuthState>()(
    persist(
        (set) => ({
            isUserAuthenticated: false,
            user_name: '',
            nick_name: '',
            user_balance: 0,

            UserLogin: (_user_name: string, _nickname: string, _balance: number) => {
                set((state: any) => ({
                    ...state,
                    isUserAuthenticated: true,
                    user_name: _user_name,
                    nick_name: _nickname,
                    user_balance: _balance,
                }));
            },
            UserLogout: () => {
                set((state: any) => ({
                    ...state,
                    isUserAuthenticated: false,
                    user_name: '',
                    nick_name: '',
                    user_balance: 0,
                }));
            },
        }),
        {
            name: 'user_auth',
        },
    ),
);
