import React from 'react';
import {Navigate} from 'react-router-dom';

//AUTHS
import LoginAdmin from 'Pages/Admins/Auth/LoginAdmin';
import DemoPage from "../Pages/Admins/Demo/DemoPage";
import SignUpAdmin from "../Pages/Admins/Auth/SignUpAdmin";

const AdminPublicRoutes = [
    { path: '/login', component: <LoginAdmin /> },
    // { path: '/signup', component: <SignUpAdmin /> }
];



const AdminAuthProtectedRoutes =
  [
        {path: '/demo',component: <DemoPage/>},
        {
          path: '/',
          exact: true,
          component: <Navigate to="/demo" />,
        },
        { path: '*', component: <Navigate to="/demo" /> },
      ];
export { AdminPublicRoutes, AdminAuthProtectedRoutes };
