import React from 'react';
import { Routes, Route } from "react-router-dom";

//Layouts
import AdminNonAuthLayout from 'Layouts/Admins/AdminNonAuthLayout';
import AdminAuthLayout from 'Layouts/Admins/AdminAuthLayout';

//routes
import { AdminPublicRoutes, AdminAuthProtectedRoutes } from "./allRoutes";
import AdminAuthProtected from './AdminAuthProtected';

const Index = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route>
                    { AdminPublicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AdminNonAuthLayout>{ route.component }</AdminNonAuthLayout>
                            }
                            key={idx}
                        />
                    )) }
                </Route>

                <Route>
                    { AdminAuthProtectedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AdminAuthProtected>
                                    <AdminAuthLayout>{ route.component }</AdminAuthLayout>
                                </AdminAuthProtected>
                            }
                            key={idx}
                        />
                    )) }
                </Route>                
            </Routes>
        </React.Fragment>
    );
};

export default Index;