import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UseAdminAuthStore } from 'store/auths';

const AdminAuthProtected = (props : any) =>{
    const { isAdminAuthenticated } = UseAdminAuthStore();  
  useEffect(() => {
  }, []);

  if ( !isAdminAuthenticated ) {
    return (        
      <Navigate to={{ pathname: "/login"}} />
    );
  }

  return <>{props.children}</>;
};

export default AdminAuthProtected;