import { APIClient } from './api_helper';
import * as url from './url_helper';

export const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user) return JSON.parse(user);
    return null;
};

export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

export const postLogin = (data: any) =>
    APIClient.PostBody(url.POST_LOGIN, data);
export const GetUsers = (data: any = null) =>
    APIClient.PostBody(url.GET_USERS, data);

export const GetJoins = (data: any = null) =>
    APIClient.PostBody(url.GET_JOINS, data);
export const ApproveJoin = (data: any = null) =>
    APIClient.PostBody(url.APPROVE_JOIN, data);
export const DenyJoin = (data: any = null) =>
    APIClient.PostBody(url.DENY_JOIN, data);

export const GetChargeRequests = (data: any = null) =>
    APIClient.PostBody(url.GET_CHARGE_REQUESTS, data);
export const ApproveCharge = (data: any = null) =>
    APIClient.PostBody(url.CHARGE_APPROVE, data);
export const DenyCharge = (data: any = null) =>
    APIClient.PostBody(url.CHARGE_DENY, data);

export const GetTransferRequests = (data: any = null) =>
    APIClient.PostBody(url.GET_TRANSFER_REQUESTS, data);
export const ApproveTransfer = (data: any = null) =>
    APIClient.PostBody(url.TRANSFER_APPROVE, data);
export const DenyTransfer = (data: any = null) =>
    APIClient.PostBody(url.TRANSFER_DENY, data);

export const addNewUser = (user: any) =>
    APIClient.PostBody(url.ADD_NEW_LEAD, user);
export const updateUser = (user: any) =>
    APIClient.update(url.UPDATE_LEAD, user);
export const deleteUser = (user: any) =>
    APIClient.delete(url.DELETE_LEAD, { headers: { user } });
