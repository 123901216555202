import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
    Spinner,
    UncontrolledAlert,
} from 'reactstrap';
import withRouter from 'Components/Common/withRouter';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {UseAdminAuthStore} from 'store/auths';

import config from 'config';
import {LoginRequest} from "../../../helpers/api_helper";

const {api} = config;

const LoginAdmin = (props: any) => {
    document.title = 'Demo Game Play SignIn';
    const {isAdminAuthenticated, Login, Logout} = UseAdminAuthStore();
    const [UserLogin, SetUserLogin] = useState<any>([]);
    const [ShowPassword, SetShowPassword] = useState<boolean>(false);

    const [Loader, SetLoader] = useState<boolean>(false);
    const [Error, SetError] = useState<string>('');

    useEffect(() => {
        console.log('isAdminAuthenticated =', isAdminAuthenticated);
        if (isAdminAuthenticated === true) {
            Logout();
        }
    }, []);

    useEffect(() => {
        SetError('');
        SetLoader(false);
    }, []);

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            username: UserLogin.username || '',
            password: UserLogin.password || '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('PLEASE ENTER USERNAME'),
            password: Yup.string().required('PLEASE ENTER PASSWORD'),
        }),
        onSubmit: (values) => {
            SetError('');
            SetLoader(true);
            ProcessUserLogin(values);
        },
    });

    const ProcessUserLogin = async (values: any) => {
        try {
            let request_url: string = api.ADMIN_API_URL + '/stanley/auth';
            await LoginRequest({
                method: 'POST',
                url: request_url,
                data: {
                    username: values.username,
                    password: values.password,
                }
            })
                .then((result: any) => {
                    SetLoader(false);
                    if (result.status === 'success') {
                        const admin = result.admin;
                        const site = result.site;
                        const token = result.token;

                        Login(
                            admin.id,
                            admin.username,
                            admin.type,
                            site.balance,
                            token.token,
                            token.refresh_token,
                        );
                        // axios.defaults.headers.common['Authorization'] =
                        //   `Bearer ${token.token}`;
                        props.router.navigate('/demo');
                    } else {
                        SetError(result.error);
                    }
                })
                .catch((error) => {
                    SetLoader(false);
                    SetError('아이디 또는 비밀번호를 확인하세요');
                });
        } catch (error) {
            SetLoader(false);
        }
    };

    return (
        <React.Fragment>
            <div className="auth-page-content mt-lg-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card color={"dark"} className="mt-4 card-bg-fill">
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-white-50">WELCOME BACK!</h5>
                                        <p className="text-white">로그인해주세요!</p>
                                    </div>

                                    <div className="p-2 mt-4">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#"
                                        >
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="username-input"
                                                    className="form-label fw-bold fs-17"
                                                >
                                                    아이디
                                                </Label>
                                                <Input
                                                    id="username-input"
                                                    name="username"
                                                    autoComplete="false"
                                                    className="form-control form-control-custom text-bg-primary"
                                                    placeholder="아이디를 입력해주세요."
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.username || ''}
                                                    invalid={
                                                        validation.touched.username &&
                                                        validation.errors.username
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.username &&
                                                validation.errors.username ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.username}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label
                                                    className="form-label fw-bold fs-17"
                                                    htmlFor="password-input"
                                                >
                                                    패스워드
                                                </Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        id="password-input"
                                                        name="password"
                                                        autoComplete="false"
                                                        value={validation.values.password || ''}
                                                        type={ShowPassword ? 'text' : 'password'}
                                                        className="form-control pe-5 form-control-custom text-bg-primary"
                                                        placeholder="패스워드를 입력해주세요"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.password &&
                                                            validation.errors.password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.password &&
                                                    validation.errors.password ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.password}
                                                        </FormFeedback>
                                                    ) : null}
                                                    <button
                                                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => SetShowPassword(!ShowPassword)}
                                                    >
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-check">
                                                <Input
                                                    className="form-check-input bg-primary"
                                                    type="checkbox"
                                                    value=""
                                                    id="auth-remember-check"
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="auth-remember-check"
                                                >
                                                    아이디 기억하기
                                                </Label>
                                            </div>
                                            {/*<div className={"d-flex justify-content-end"}>
                                                <Button block={false} size={"sm"} color={'link'}
                                                        href="/signup">
                                                    회원가입</Button>
                                            </div>*/}
                                            {Error.length > 0 && (
                                                <UncontrolledAlert
                                                    color="danger"
                                                    className="alert-solid alert-dismissible bg-danger text-white alert-label-icon show mb-xl-0 material-shadow"
                                                    closeClassName="btn-close-white"
                                                >
                                                    <i className="ri-error-warning-line label-icon"></i>
                                                    {Error}
                                                </UncontrolledAlert>
                                            )}

                                            <div className="mt-4">
                                                <Button
                                                    color="info"
                                                    disabled={Loader && true}
                                                    className="btn btn-success w-100"
                                                    type="submit"
                                                >
                                                    {Loader && (
                                                        <Spinner size="sm" className="me-2">
                                                            {' '}
                                                            로딩중...{' '}
                                                        </Spinner>
                                                    )}
                                                    로그인
                                                </Button>
                                                <br/>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(LoginAdmin);
