import { createSlice } from "@reduxjs/toolkit";
import { getUsers, addNewUser, updateUser, deleteUser,
    getJoins, approveJoin, denyJoin, 
    getChargeRequests, approveCharge, denyCharge,
    getTransferRequests, approveTransfer, denyTransfer,
} from "./thunk";

export const initialState:any = {
    isUserCreated : false,
    users: [],
    joins: [],
    charges: [],
    transfers: [],
    error: {}
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsers.fulfilled, (state:any, action:any) => {
            state.users = action.payload;
            state.isUserCreated = false;
            state.isUsersSuccess = true;
        });

        builder.addCase(getUsers.rejected,(state:any, action:any) => {
        state.error = action.payload.error || null;
        state.isUserCreated = false;
        state.isUsersSuccess = false;
        });

        builder.addCase(addNewUser.fulfilled,(state:any, action:any) => {
        state.users.unshift(action.payload);
        state.isUserCreated = true;
        state.isUsersAdd = true;
        state.isUsersAddFail = false;
        });

        builder.addCase(addNewUser.rejected, (state:any, action:any) => {
        state.error = action.payload.error || null;
        state.isUsersAdd = false;
        state.isUsersAddFail = true;
        });

        builder.addCase(updateUser.fulfilled, (state:any, action:any) => {
        state.users = state.users.map((user:any) =>
            user.id === action.payload.id
            ? { ...user, ...action.payload }
            : user);
        state.isUsersUpdate = true;
        state.isUsersUpdateFail = false;
        });

        builder.addCase(updateUser.rejected, (state:any, action:any) => {
        state.error = action.payload.error || null;
        state.isUsersUpdate = false;
        state.isUsersUpdateFail = true;
        });

        builder.addCase(deleteUser.fulfilled, (state:any, action:any) => {
        state.users = state.users.filter(
            (user:any) => user.id.toString() !== action.payload.users.toString()
        );
        state.isUsersDelete = true;
        state.isUsersDeleteFail = false;
        });

        builder.addCase(deleteUser.rejected, (state:any, action:any)=> {
            state.error = action.payload.error || null;
            state.isUsersDelete = false;
            state.isUsersDeleteFail = true;
        });

        //join
        builder.addCase( getJoins.fulfilled, (state:any, action:any ) => {
            state.joins = action.payload;
            state.isJoinCreated = false;
            state.isJoinsSuccess = true;
        });

        builder.addCase( getJoins.rejected,(state:any, action:any) => {
            state.error = action.payload.error || null;
            state.isJoinCreated = false;
            state.isJoinsSuccess = false;
        });

        builder.addCase( approveJoin.fulfilled, (state:any, action:any) => {
            state.joins = action.payload;
            state.isJoinApprove = true;
            state.isJoinApproveFail = false;
        });
    
        builder.addCase( approveJoin.rejected, (state:any, action:any)=> {
            state.error = action.payload.error || null;
            state.isJoinApprove = false;
            state.isJoinApproveFail = true;
        });

        builder.addCase( denyJoin.fulfilled, (state:any, action:any) => {
            state.joins = action.payload;
            state.isJoinDeny = true;
            state.isJoinDenyFail = false;
        });
    
        builder.addCase(denyJoin.rejected, (state:any, action:any)=> {
            state.error = action.payload.error || null;
            state.isJoinDeny = false;
            state.isJoinDenyFail = true;
        });

        //charge
        builder.addCase( getChargeRequests.fulfilled, (state:any, action:any ) => {
            state.charges = action.payload;
            state.isChargeCreated = false;
            state.isChargeSuccess = true;
        });

        builder.addCase( getChargeRequests.rejected,(state:any, action:any) => {
            state.error = action.payload.error || null;
            state.isChargeCreated = false;
            state.isChargeSuccess = false;
        });

        builder.addCase( approveCharge.fulfilled, (state:any, action:any) => {
            state.charges = action.payload;
            state.isChargeApprove = true;
            state.isChargeApproveFail = false;
        });
    
        builder.addCase( approveCharge.rejected, (state:any, action:any)=> {
            state.error = action.payload.error || null;
            state.isChargeApprove = false;
            state.isChargeApproveFail = true;
        });

        builder.addCase( denyCharge.fulfilled, (state:any, action:any) => {
            state.charges = action.payload;
            state.isChargeDeny = true;
            state.isChargeDenyFail = false;
        });
    
        builder.addCase( denyCharge.rejected, (state:any, action:any)=> {
            state.error = action.payload.error || null;
            state.isChargeDeny = false;
            state.isChargeDenyFail = true;
        });

        //transfer
        builder.addCase( getTransferRequests.fulfilled, (state:any, action:any ) => {
            state.transfers = action.payload;
            state.isTransferCreated = false;
            state.isTransferSuccess = true;
        });

        builder.addCase( getTransferRequests.rejected,(state:any, action:any) => {
            state.error = action.payload.error || null;
            state.isTransferCreated = false;
            state.isTransferSuccess = false;
        });

        builder.addCase( approveTransfer.fulfilled, (state:any, action:any) => {
            state.transfers = action.payload;
            state.isTransferApprove = true;
            state.isTransferApproveFail = false;
        });
    
        builder.addCase( approveTransfer.rejected, (state:any, action:any)=> {
            state.error = action.payload.error || null;
            state.isTransferApprove = false;
            state.isTransferApproveFail = true;
        });

        builder.addCase( denyTransfer.fulfilled, (state:any, action:any) => {
            state.transfers = action.payload;
            state.isTransferDeny = true;
            state.isTransferDenyFail = false;
        });
    
        builder.addCase( denyTransfer.rejected, (state:any, action:any)=> {
            state.error = action.payload.error || null;
            state.isTransferDeny = false;
            state.isTransferDenyFail = true;
        });        
    },
});

export default userSlice.reducer;